<template>
  <div>
    <div class="custom-pt-12">
      <div class="custom-px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="custom-w-5 lg-w-3 custom-bg-white mx-auto p-2 md-p-10 mb-10 custom-rounded-2xl custom-shadow-lg relative z-0">
          <a class="CustomButton_Back" v-if="!loading" @click="returnToTemplates">
            Return to Templates
          </a>
          <div v-if="!loading">
            <div class="mx-auto custom-w-2 text-center custom-pt-2">
              <h1 class="custom-text-3xl">
                NIHSS+ Evaluation
              </h1>
              <hr class="custom-hr my-3">
            </div>
            <div v-for="(block) in evaluation" :key="block.id">
              <eval-section :data="block" />
            </div>
            <div class="custom-px-10 my-6 custom-pt-2 custom-w-full text-center">
              <a class="CustomButton" href="#" @click="SubmitEvalPreview">
                Submit Evaluation
              </a>
            </div>
          </div>
          <div v-else>
            <div class="mx-auto custom-w-2 text-center custom-pt-2">
              <h1 class="custom-text-3xl">
                Loading Evaluation
              </h1>
              <h5 style="font-weight: 400; color: black;">Please wait, this may take some minutes</h5>
              <hr class="custom-hr my-3">
            </div>
            <div class="custom-px-10 my-6 custom-pt-2 custom-w-full text-center">
              <va-inner-loading :loading="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
  import firebase from 'firebase';
  import section from './eval/section.vue';
  import {mapActions, mapGetters} from 'vuex';
  import axios from '@/scripts/interceptor.js'
  export default {
    components: {
      evalSection: section,
    },
    data() {
      return {
        loading: true,
        submitting: false,
        evaluation: '',
        promotion: false,
        otherText: false,
        inError: true,
        btnLabel: "Submit Evaluation"
      };
    },
    created() {
      firebase.firestore().collection('evalTemplates').doc(this.$route.params.evalId).get().then((snapshot)=>{
        this.evaluation = snapshot.data().evaluationLastPreview;
        this.$store.dispatch('setEvaluation', snapshot.data().evaluationLastPreview);
        this.loading = false;
        this.inError = false;
      }).catch((error)=> {
        console.log(error);
        this.loading = false;
        this.$swal.fire({
          title: "Oops!",
          text: "Error loading evaluation. Please try again.",
          icon: "error",
          confirmButtonText: "OK",
        })
      })
    },
    methods: {
      ...mapActions(['successMessage', 'errorMessage']),
      SubmitEvalPreview(){
        this.$swal.fire({
          title: "Oops!",
          text: "This feature is not available in the preview mode.",
          icon: "info",
          confirmButtonText: "OK",
        })
      },
      returnToTemplates(){
        this.$router.push({name: 'eval', params: {evalId: this.$route.params.evalId}});
      },
    },
    computed: {
      ...mapGetters(['evaluationQuestions']),
    },
  };
  </script>
  <style>
  .custom-pt-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .custom-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .custom-bg-white {
    background-color: #ffffff;
  }

  .custom-w-5 {
    width: 62.5%;
  }

  .CustomButton {
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #2196f3;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }

  .CustomButton_Back {
    cursor: pointer;
    color: #ffffff;
    background: #2196f3;
    border: 1px solid #2196f3;
    padding: 3px;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
  }

  .lg-w-3 {
    width: 85%;
  }

  .custom-rounded-2xl {
    border-radius: 1rem;
  }

  .custom-shadow-lg {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .custom-z-0 {
    z-index: 0;
  }

  .my-3 {
    margin-top: 0.55rem;
    margin-bottom: 0.55rem;
  }

  .custom-w-2 {
    width: 66.666%;
  }

  .custom-pt-2 {
    padding-top: 0.5rem;
  }

  .custom-text-3xl {
    color: black;
    font-size: 1.875rem;
    font-weight: 500;
  }

  .custom-rounded {
    border-radius: 0.25rem;
  }

  .custom-hr {
    border-top-width: 3px;
    border-top-color: #e53e3e;
    border-top-style: solid;
    width: 50%;
  }

  .custom-px-10 {
    padding: 2.5rem;
  }

  .custom-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .custom-w-full {
    width: 100%;
  }

  .custom-text-center {
    text-align: center;
  }
</style>