<template>
  <textarea
    class="custom-textarea"
    id="comments"
    name="comments"
    rows="3"
    style="resize: none;"
    placeholder=""
    v-model="answer"
    @input="set"
  />
</template>

<script>
export default {
  data () {
    return {
      answer: '',
    };
  },
  //when user text is entered, emit the text to the parent component
  methods: {
    set() {
      this.$emit('answer', this.answer);
    },
  },

};
</script>

<style>
  .custom-textarea {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: normal;
    color: #4a5568; /* Altere a cor do texto conforme necessário */
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #cbd5e0; /* Altere a cor da borda conforme necessário */
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 0.5rem;
  }

  .custom-textarea:focus {
    color: #4a5568; /* Altere a cor do texto ao focar conforme necessário */
    background-color: #ffffff;
    border-color: #3182ce; /* Altere a cor da borda ao focar conforme necessário */
    outline: none;
  }
</style>