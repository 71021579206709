import axios from 'axios';
import firebase from 'firebase';

let baseURL = 'https://us-central1-nihss-bb52c.cloudfunctions.net/';
if (window.location.host.indexOf('local') !== -1) {
    baseURL = 'http://localhost:5001/nihss-bb52c/us-central1/';
}
// Create an Axios instance with default configuration
const axiosInstance = axios.create({
  baseURL:  baseURL,
});
// Add a request interceptor to add the Firebase Authentication token to every request
axiosInstance.interceptors.request.use(async config => {
  // Get the current user
  const user = firebase.auth().currentUser;
  // Get the user's ID token
  const token = await user.getIdToken();
  // Add the token to the request headers
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;