<template>
  <fieldset
    v-if="data && checkAccreditation()"
    class="custom-fieldset"
  >
    <legend class="custom-legend">
      {{ data.data.header }}
    </legend>
    <div
      v-for="(question,index) in data.data.questions"
      :key="question.id"
    >
      <div>
          <question
          v-if="!question.hidden"
          :question-data="question"
          :first="index === 0"
          />
      </div>
      <!-- {{ question.displayName }} -->
    </div>
  </fieldset>
</template>
<script>
import question from './question.vue';
import {mapGetters} from 'vuex';
import firebase from 'firebase';
export default {
  components: {
    question,
  },
  props: ['data'],
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      
    };
  },
  methods :{
    checkAccreditation(question = false) {
     let accreditor = this.data.data.accreditor
      if (!accreditor) {
        return true;
      }
      let userCredentialCode = this.user.data.credentialCode;
      return accreditor.some(el => el.value === userCredentialCode);
    }
  }
};
</script>

<style>
  .custom-fieldset {
    border: 1px solid #cbd5e0;
    border-radius: 0.375rem;
    padding: 0.75rem;
  }

  .custom-legend {
    font-size: 1rem;
    color: black;
    font-weight: bold;
  }
</style>