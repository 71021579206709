<template>
  <div class="custom-radio-container flex mt-2 text-center text-md">
    <div class="custom-radio-options">
      <label
        v-for="(answer, index) in answers"
        :key="index"
        class="custom-radio-label cursor-pointer"
      >
        <input
          type="radio"
          :name="id"
          :value="index"
          class="custom-radio-input cursor-pointer"
          v-model="answerChoice"
        >
        {{ answer.label }}
      </label>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  props: ['answers', 'id'],
  computed: {
    ...mapGetters(['getAnswerById']),
    answerChoice: {
      get() {
        return this.getAnswerById(this.id).answerChoice;
      },
      set(value) {
        this.$emit('answer', value);
      },
    },
  },
};
</script>

<style>
  .custom-radio-container {
    display: flex;
    margin-top: 1rem;
  }

  .custom-radio-options {
    display: flex;
  }

  .custom-radio-label {
    padding-left: 0.5rem;
    cursor: pointer;
  }

  .custom-radio-input {
    cursor: pointer;
    margin-right: 0.5rem; /* Ajuste conforme necessário para espaçamento entre as opções */
  }
</style>